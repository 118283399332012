<template>
  <div>
    <b-table
      v-if="reservations.length > 0"
      :items="reservations"
      :fields="fields"
      :sort-desc="true"
      sort-by="DateTimeBusinessStart"
      responsive
    >
      <template v-slot:cell(Status)="data">
        <span>{{
          reservationStatus(
            data.item.Status || data.item.ReservationStatus,
            data.item.WarehouseID
          )
        }}</span>
      </template>
      <template v-slot:cell(ReservationID)="data">
        <router-link
          :to="{
            name: 'reservation',
            params: { reservationID: data.item.ReservationOrderID }
          }"
          >#{{ data.item.ReservationOrderID }}</router-link
        >
      </template>

      <template v-slot:cell(DateTimeBusinessStart)="data">
        {{ formatDate(data.item.DateTimeBusinessStart) }}
      </template>
      <template v-slot:cell(DateTimeExpectedEnd)="data">
        {{ formatDate(data.item.DateTimeExpectedEnd) }}
      </template>
      <template v-slot:cell(ReportID)="data">
        <span
          v-if="data.item.ReportID"
          @click="
            getReport({
              reportID: data.item.ReportID,
              reservationOrderID: data.item.ReservationOrderID,
              reportFileName: data.item.ReportFileName,
              attachmentToken: data.item.AttachmentToken
            })
          "
          class="text-primary"
          style="cursor:pointer"
          variant="link"
          >{{ data.item.ReportFileName }}</span
        >
      </template>
      <template v-slot:cell(ContactFirstName)="data">
        {{ data.item.ContactFirstName }} {{ data.item.ContactLastName }}
      </template>
    </b-table>
    <p v-else>
      U heeft nog geen reserveringen om te bekijken. Wanneer u een nieuwe
      reservering aanmaakt zal deze hier worden getoond.
    </p>
  </div>
</template>

<script>
import {
  getReservations,
  getReservationReport
} from '@/services/ReservationService'

import { BTable } from 'bootstrap-vue'

import formatDate from '@/functions/formatDate'

export default {
  name: 'Reservations',
  components: {
    BTable
  },
  data() {
    return {
      reservations: [],
      fields: [
        {
          key: 'ReservationID',
          label: 'Aanvraag'
        },
        {
          key: 'Status',
          label: 'Status'
        },

        {
          key: 'WarehouseID',
          label: 'Locatie'
        },
        {
          key: 'DateTimeBusinessStart',
          label: 'Afhaaldatum',
          sortable: true
        },
        {
          key: 'DateTimeExpectedEnd',
          label: 'Terugbrengdatum',
          sortable: true
        },

        {
          key: 'ReservationOrderID',
          label: '',
          class: 'd-none'
        },
        {
          key: 'AttachmentToken',
          label: '',
          class: 'd-none'
        }
      ]
    }
  },
  created: async function() {
    this.reservations = await getReservations()
    this.$store.commit('setReservations', { reservations: this.reservations })
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    reservationStatus: function(status) {
      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return 'Aangevraagd'

      if (status === 'Order') return 'Reservering bevestigd'

      if (status === 'Pick' || status === 'Picked') return 'In behandeling'

      if (status === 'Pending') return 'Uitgeleend'

      if (status === 'Closed') return 'Ingeleverd'

      if (status === 'Cancelled' || status === 'Expired') return 'Geannuleerd'

      if (status === 'Rejected') return 'Afgewezen'

      return 'Aangevraagd'
    },
    getReport: async function({
      reportID,
      reservationOrderID,
      reportFileName,
      attachmentToken
    }) {
      await getReservationReport({
        reservationOrderID: reservationOrderID,
        reportID: reportID,
        attachmentToken,
        fileName: `${reservationOrderID}-${reportFileName}`
      })
    }
  }
}
</script>
